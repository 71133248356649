import { useEffect } from 'react';

import { useRouter } from '../../hooks/useRouter';
import { Urls } from '../urls';

import { Features as FeaturesElement } from '../../components/organisms/Features';
import { Faq, Faqs } from '../../components/atoms/Faq';
import { Spacer } from '../../components/atoms/Spacer/Spacer';
import { Copy, H1, H2 } from '../../components/atoms/Typography';
import { Pricing } from '../../components/molecules/Pricing';

const PricingPlan = () => {
  const { navigate } = useRouter();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <H1>Pricing Plan</H1>
      <Copy styleLevel={1} marginBottom={40}>
        Maximize your job search capabilities with our premium plan. Harness the unrivaled power of AI to receive expert
        assistance in crafting your resume, cover letter, or seamlessly importing your job applications. Experience the
        ultimate support system that propels you towards career success.
      </Copy>
      <Pricing
        handlePlanSelect={() => {
          navigate(`/${Urls.Login}`);
        }}
      />
      <Spacer y={62} />
      <H2>Frequently asked questions</H2>
      <Faqs>
        <Faq title="What’s your pricing?" category="payment">
          Our pricing varies based on the specific Premium plan you choose. For a detailed breakdown of our Premium
          plans, please scroll to the top of this page. It’s important to note that the job search process typically
          spans from three to six months for most individuals.
        </Faq>
        <Faq title="Can I get a free trial?">
          Absolutely! JobsBoard.io offers a free version for users who do not opt for paid customization features. Enjoy
          the basic functionalities without any cost!
        </Faq>
        <Faq title="Is there any limit on the number of Job Applications?">
          While the Free plan offers 3 Jobs Boards and 20 Job Applications, our Premium plan opens the door to unlimited
          Job Applications, providing unparalleled flexibility and control.
        </Faq>
        <Faq title="What’s your refund policy?" category="legal">
          We offer a 14-day money-back guarantee. If you are not satisfied with our product, we will refund your
          purchase - no questions asked!
        </Faq>
        <Faq title="What’s your cancellation policy?" category="legal">
          You may cancel at any time directly in JobsBoard.io or contact our support team to help. Once you cancel your
          subscription, you will not be charged further. However, you will not be refunded for the current billing
          cycle.
        </Faq>
        <Faq title="Is there a time limit to use JobsBoard.io?">
          There is no time limit for using JobsBoard.io. You can use the platform indefinitely with basic customization
          options without any charge. The platform offers advanced customization features which come with a cost,
          clearly labeled within the app interface. You have the freedom to organize your job search effectively and
          choose to pay only for the additional, enhanced functionalities you need.
        </Faq>
        <Faq title="Can I change my plan later?" category="payment">
          Yes, you can change your plan at any time. You can upgrade or downgrade your plan directly from the app
          interface. The changes will be reflected in your next billing cycle.
        </Faq>
        <Faq title="Is my data safe and secure?">
          Rest assured, your data is safeguarded with state-of-the-art encryption measures to uphold its
          confidentiality. Your credit card information is never stored for an extra layer of security. Routine backups
          are diligently performed to prevent data loss, providing an added layer of protection and facilitating swift
          recovery processes for your peace of mind.
        </Faq>
      </Faqs>

      <Spacer y={64} />

      <FeaturesElement />
    </>
  );
};

export default PricingPlan;
